<header class="main-header dark-bg py-2">
    <div class="container-fluid px-4">
      <div class="row align-items-center">
        <div class="col-md-6 col-12">
          <div class="brand-logo position-relative">
            <a><img alt=" " class="img-fluid img-loog" src="assets/images/Logo-square.png" /></a>
          </div>
        </div>
      </div>
    </div>
  </header>