import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AUTH_KEYS } from 'src/app/core/enums';
import { AESEncryptDecryptServiceService } from 'src/app/shared/services/aesencrypt-decrypt-service.service';
import { MainService } from '../public-api';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  profile: any;

  constructor(
    private cookieService: CookieService,
    private aesService: AESEncryptDecryptServiceService,
    private mainService: MainService
  ) {
    this.mainService.profileDetail$.subscribe((res: any) => {
      if (res && res?.assignedRole) {
        this.setAccountType = res?.assignedRole?.name || '';
      }
    });
  }

  get getUserPermissions() {
    return localStorage.getItem(AUTH_KEYS.USER_PERMISSION_TYPE || []);
    // return this.cookieService.get(AUTH_KEYS.USER_PERMISSION_TYPE || []);
  }

  get getPermissions() {
    return this.cookieService.get(AUTH_KEYS.USER_PERMISSION) || [];
  }

  get accountType() {
    return this.cookieService.get(AUTH_KEYS.USER_ACCOUNT_TYPE) || '';
  }

  hasPermissions(permission: string, read?: boolean, write?: boolean): boolean {
    const assignedPermission = this.aesService.decryptData(
      this.getUserPermissions
    );
    if (this.isAdmin()) {
      return true;
    }
    const currentPermission = assignedPermission.find(
      (ele: any) => ele.module === permission
    );
    if (currentPermission) {
      if (read && !write) {
        return currentPermission.read;
      }
      if (read && write) {
        return currentPermission.write;
      }
    }
    return false;
  }

  isAdmin(): boolean {
    if (this.accountType === 'admin') {
      return true;
    }
    return false;
  }

  hasSubPermissions(permission: string): boolean {
    let returnValue: boolean = false;
    this.aesService.decryptData(this.getPermissions).forEach((ele: any) => {
      if (ele.name === permission) {
        returnValue = true;
      }
    });
    return returnValue;
  }

  get getAuthToken(): string {
    return this.cookieService.get('accessToken') || '';
  }

  set setAuthToken(token: any) {
    this.cookieService.set('accessToken', token, { path: '/' });
  }

  set setAccountType(role: string) {
    this.cookieService.set('csp_account_type', role, { path: '/' });
  }

  set setPermissions(data: any) {
    localStorage.setItem('permissions', this.aesService.encryptData(data));
    this.cookieService.set(
      'permissions',
      JSON.stringify(this.aesService.encryptData(data)),
      { path: '/' }
    );
  }

  get getUserDetail(): string {
    return this.cookieService.get('user') || '';
  }

  set setUserDetail(token: any) {
    this.cookieService.set('user', token, { path: '/' });
  }
  get getLoginCreds(): any {
    return this.cookieService.get('userLogins')
      ? this.aesService.decryptData(
          JSON.parse(this.cookieService.get('userLogins'))
        )
      : '';
  }

  set setLoginCreds(creds: any) {
    this.cookieService.set(
      'userLogins',
      JSON.stringify(this.aesService.encryptData(creds)),
      { path: '/' }
    );
  }

  clearLogins() {
    this.cookieService.delete('userLogins', '/');
  }

  clearCookie() {
    this.cookieService.delete('user', '/');
    this.cookieService.delete('accessToken', '/');
    this.cookieService.delete('permissions', '/');
  }
}
