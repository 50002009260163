import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  @Input() content: string = ''
  @Input() contentObject: any;
}
